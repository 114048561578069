.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "text_font";
  width: 100%;
}

.profile .details {
  display: flex;
  color: var(--text-color);
  gap: 50px;
  justify-content: center;
  align-items: center;
}
.details .lottie {
  scale: 1.5;
}
.details .cards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.details .card {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.card p {
  margin: 0;
}
.card svg {
  width: 20px;
  height: 20px;
}
.change_pass {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.change_pass h1 {
  color: var(--text-color);
}
.change_pass input {
  border: none;
  border-bottom: 1px solid var(--primary-color);
  padding: 10px 15px 0;
  font-size: 15px;
  color: var(--text-color);
  direction: ltr;
}
input:focus {
  outline: none;
}
.change_pass button {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--normal-color);
  border-radius: 20px;
  border: none;
  box-shadow: #333 0 5px 5px;
}
@media (max-width: 768px) {
  .profile .details {
    flex-direction: column;
  }
}
