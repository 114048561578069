@font-face {
  font-family: main_font;
  src: url("../../assets/fonts/Kufam-VariableFont_wght.ttf");
}
@font-face {
  font-family: second_font;
  src: url("../../assets/fonts/SugarpunchDEMO.otf");
}

.home {
  position: relative;
  overflow: hidden;
  background-color: #fff;
}
.scroll {
  position: absolute;
  bottom: 5px;
  margin: auto;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  font-family: "second_font";
  animation: upanddown 1s infinite alternate;
  font-weight: 900;
  letter-spacing: 1px;
}
.scroll span:nth-of-type(2) {
  border-right: 5px solid var(--secondary-color);
  border-bottom: 5px solid var(--secondary-color);
  width: 20px;
  height: 20px;
  background-color: transparent;
  transform: rotateZ(45deg);
}
@keyframes upanddown {
  to {
    transform: translateY(20px);
  }
}

.edge {
  position: absolute;
  bottom: 0;
  left: 0px;
  width: fit-content;
  height: 200px;
  z-index: 2;
}

.portfolio {
  flex: 1;
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  font-size: 3vw;
  position: relative;
}

.portfolio .portofolio_lottie {
  width: 5em !important;
  height: 5em !important;
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translateX(-50%);
}
.portfolio .text {
  display: flex;
  flex-direction: column;
  height: fit-content;
  position: relative;
  text-wrap: nowrap;
}
.portfolio .text h1 {
  font-size: 1.1em;
  color: var(--secondary-color);
  margin: 0;
  font-family: "second_font";
}
.portfolio .text h2 {
  font-size: 2em;
  color: var(--primary-color);
  margin: 0;
  margin-right: 40px;
  margin-top: 10px;
  font-family: "main_font";
  text-align: left;
  position: relative;
}
.portfolio h2::first-letter {
  font-size: 1.2em;
  vertical-align: text-top;
}

.portfolio h2 span {
  position: absolute;
  bottom: 10%;
  left: 5%;
  letter-spacing: 5px;
  color: var(--secondary-color);
  font-size: 0.2em;
  text-transform: uppercase;
  font-family: "second_font";
}

.services {
  display: grid;
  grid-template-columns: 1fr 2fr;
  border-radius: 20px;
  width: 100vw !important;
  align-items: center;
  width: 100%;
  height: 100vh;
  gap: 20px;
  justify-items: center;
  position: relative;
}
.upperwave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  rotate: 180deg;
}
.lowerwave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.services svg,
.portfolio svg {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.services .text {
  display: flex;
  flex-direction: column;
  min-width: 500px;
  font-size: 1rem;
  position: relative;
}
.services .text::after {
  content: "";
  position: absolute;
  width: 20%;
  height: 5px;
  bottom: 0;
  left: 0;
  background-color: var(--secondary-color);
  border-radius: 50px;
}
.services .text .firstH1 {
  position: relative;
}
.services .text .firstH1 span {
  border-radius: 20px;
  position: absolute;
  top: -20px;
  right: -50px;
  width: 50%;
  height: 50%;
  z-index: 1;
  overflow: hidden;
}
.services .text .firstH1 span::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  top: 0;
  background-color: var(--secondary-color);
}
.services .text h1 {
  position: relative;
  z-index: 2;
  color: #333;
  font-size: 4em;
}
.services .text h2 {
  position: relative;
  color: var(--primary-color);
  font-size: 2em;
  margin: 0;
}
.services .text h1::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -40%;
  right: -20px;
  width: 100%;
  height: 70%;
  border-radius: 20px;
}

/* ////////////////// */
.years {
  position: relative;
  font-family: "text_font";
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100vh;
  padding: 50px 0;
  gap: 50px;
}
.years .row {
  display: flex;
  justify-items: center;
  gap: 20px;
  width: 100%;
}
.normalitem,
.special_item {
  border-radius: 10px;
  aspect-ratio: 2/1.3;
  width: 100%;
  transition-duration: 0.3s;
  box-shadow: var(--shadow);
  overflow: hidden;
  position: relative;
}
.special_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 50%;
  height: 50px;
  transition-duration: 1s;
  z-index: 1;
  padding: 10px 0;
  border: 1px solid var(--secondary-color);
  box-shadow: none;
  font-size: 1.3em;
}
.normalitem img {
  transition-duration: 0.3s;
  filter: grayscale(0.7);
}

.normalitem a {
  position: absolute;
  text-align: center;
  margin: 0;
  top: 50%;
  right: 50%;
  translate: 50% -50%;
  font-size: 4em;
  line-height: 1em;
  color: var(--secondary-color);
  font-weight: bold;
}
.normalitem:hover {
  scale: 1.05;
}
.normalitem img {
  width: 100%;
  height: 100%;
}
.normalitem:hover img {
  scale: 1.1;
  filter: grayscale(0);
}

.normalitem a:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

.special_item svg {
  fill: var(--secondary-color);
  transition-duration: 1s;
}

.special_item div {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  justify-items: center;
  align-items: center;
  gap: 5px;
  color: var(--secondary-color);
  padding: 10px;
  text-align: center;
  width: 100%;
  justify-content: space-evenly;
}
.special_item:hover {
  border-color: var(--primary-color);
}
.special_item:hover svg {
  fill: var(--primary-color);
  scale: 1.3;
}
.special_item:hover div {
  color: var(--primary-color);
}

.footer {
  background-color: #333;
  width: 100%;
  height: 20px;
  padding: 5px 0;
  color: var(--normal-color);
  text-align: center;
  font-size: 12px;
  text-decoration: underline;
}
.footer a {
  text-decoration: none;
  color: #eee;
}

@media (max-width: 450px) {
  .portfolio {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 3fr;
    font-size: 6vw;
    margin: 50px 0;
    padding: 0 !important;
  }
  .services {
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    padding-bottom: 100px;
    height: fit-content;
  }
  .services .text {
    font-size: 0.6rem;
    margin: auto;
    min-width: 100%;
    padding: 20px;
    box-sizing: border-box;
    justify-content: center;
  }
  .services .text #secondh1 {
    margin-right: 2.8em !important;
  }

  .years .row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .years .row div {
    width: 100%;
  }
  .years .row .special_item {
    width: 100%;
  }
  .edge {
    height: 100px;
  }
  .footer {
    margin-top: 0;
  }
}
@media (max-width: 768px) {
  .home .inner {
    flex-direction: column;
    overflow: visible;
  }
  .portfolio {
    padding-bottom: 70px;
  }
  .services {
    flex-direction: column;
  }
  .services .cards {
    width: 100%;
  }
  .footer {
    position: static;
    margin-top: 20px;
  }

  .years .middle_col {
    gap: 20px;
  }
}
