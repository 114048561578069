.admin {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.add_element_active_panal {
  display: flex;
}
.add_mcq_inputs div {
  position: relative;
  display: flex;
}
.add_mcq {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 300px;
}
.add_mcq_inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.admin input {
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  font-size: 17px;
  padding: 5px 15px;
}
.add_mcq_inputs div span {
  width: 20px;
  border-radius: 10px;
  height: 100%;
  border: 1px solid var(--primary-color);
  position: absolute;
  left: -25px;
}
.add_mcq_bts {
  display: flex;
  justify-content: space-between;
}
.admin button {
  border: none;
  padding: 10px 20px;
  font-size: 20px;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 10px;
}
.add_video,
.add_file {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.add_video input,
.add_file input {
  width: fit-content;
}
.admin .courses_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}
.courses_list .course {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 250px;
}
.courses_list .course img {
  width: 100%;
  height: 150px;
}
.courses_list .course .spans {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.course .spans svg {
  width: 50px;
}
.add_course {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.faqs .faqs_nav {
  width: 100%;
  gap: 10px;
  border-bottom: 1px solid var(--primary-color);
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 10px;
}
.faqs_list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.faqs form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.banned_users,
.unapproved_users,
.examsو .reset_device_list,
.users {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.banned_users .banned_user,
.unapproved_user,
.exam .upper_section,
.record,
.reset_device_card,
.user,
.users_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
}
.users {
  width: 100%;
  overflow-x: scroll;
}
.users table {
  -webkit-border-vertical-spacing: 5px;
  -webkit-border-horizontal-spacing: 3px;
}
table td {
  padding: 5px 10px;
  border-radius: 5px;
}
table tr {
  outline: 1px dotted var(--primary-color);
}
.record {
  background-color: #d1d1d1;
}

.exam .records {
  margin: auto;
  margin-top: 10px;
  width: 95%;
  max-height: 400px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.banned_user span,
.unapproved_user span,
.exam span {
  background-color: var(--primary-color);
  border-radius: 10px;
  padding: 5px 15px;
  color: var(--normal-color);
  cursor: pointer;
}
.banned_users p,
.exam p {
  text-align: center;
}
