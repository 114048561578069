.years{
    display: flex;
    flex-wrap: wrap;
     gap: 30px;
     margin-top: 100px;
     width: 100%;
     height: 100%;
     padding: 0 50px 50px;
}
.years div{
    width: 300px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--primary-color);
    /* padding: 30px; */
    overflow: hidden;
    border-radius: 30px;
    text-align: center;
    padding-bottom: 10px;
}
.years p {color: var(--secondary-color);}
.years div img{
    width: 100%;
}