.courses {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: 100%;
}
.courses .course {
  border-radius: 10px;
  aspect-ratio: 2/1.3;
  width: 300px;
  transition-duration: 0.3s;
  box-shadow: var(--shadow);
  overflow: hidden;
  position: relative;
}
.courses .course img {
  filter: grayscale(0.5);
  height: 100%;
}
.course p {
  position: absolute;
  text-align: center;
  margin: 0;
  top: 50%;
  right: 50%;
  translate: 50% -50%;
  font-size: 35px;
  color: var(--secondary-color);
  width: 100%;
}
@media (max-width: 768px) {
  .courses .course {
    width: 100%;
  }
}
