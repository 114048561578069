.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100vh;
}
.form img {
  filter: grayscale(0.2);
  height: 100%;
}
.form form {
  height: 100%;
  background-color: #fff;
}
.form .inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  padding-top: 150px;
  box-sizing: border-box;
}
.form .inner div {
  display: flex;
  position: relative;
  padding-top: 10px;
  align-items: center;
  width: 100%;
}
.form .inner p {
  font-size: 14px;
  background-color: #eee;
  border-radius: 10px;
  width: fit-content;
  padding: 7px 30px;
}
.form .inner #pass div {
  position: absolute;
  left: 10px;
  top: 50%;
  translate: 0 -50%;
  width: fit-content;
}
.form .inner #pass div svg {
  width: 25px;
  height: 25px;
  fill: var(--secondary-color);
}
hr {
  width: 20%;
  background-color: var(--text-color);
}
.form input {
  width: 100%;
  padding-top: 15px;
  border: none;
  outline: none;
  font-size: 15px;
  border-bottom: var(--primary-color) 1px solid;
  color: var(--text-color);
}
.form input[name="submit"] {
  background-color: var(--primary-color);
  color: var(--normal-color);
  font-size: 25px;
  padding: 5px 20px;
  margin-top: 10px;
  border-radius: 10px;
  cursor: pointer;
  border: 3px solid #fff;
  outline: 2px solid var(--primary-color);
  box-sizing: border-box;
}
.form button {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  display: flex;
}
.form button img {
  height: 50px;
  width: 50px;
}
.form input[name="submit"]:hover {
  background-color: transparent;
  color: var(--primary-color);
}
.form .inner div span {
  position: absolute;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  color: #666;
}
.form .inner select {
  width: 100%;
  border: none;
  padding: 10px;
  font-size: 17px;
  color: var(--text-color);
  border-radius: 10px;
  font-weight: bold;
}
@media (max-width: 450px) {
  .form {
    width: 100vw;
    height: 100vh;
    display: flex;
  }
  .form form {
    width: 100%;
    height: 100%;
  }
}
