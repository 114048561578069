.user {
  margin-top: 100px;
}
.user .inner {
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  padding-right: 0;
  display: flex;
  background-color: #fff;
  border-radius: 10px;
}
.user .right_col {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 150px;
  border-radius: 10px;
  width: 200px;
}
.user .right_col button {
  background-color: #fff;
  color: var(--text-color);
  border: none;
  width: 100%;
  padding: 10px;
  font-family: text_font;
  font-weight: 600;
  font-size: 15px;
  transition-duration: 0.3s;
}
.user .right_col button.active {
  background-color: var(--primary-color);
  color: var(--normal-color);
}
.user .left_col {
  padding: 10px;
  background-color: #eee;
  width: 100%;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .user .inner {
    flex-direction: column;
    background-color: #eee;
    padding: 0;
    margin-bottom: 50px;
  }
  .user .right_col {
    flex-direction: row;
    padding: 0;
    border: 0;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    z-index: 2;
  }
  .user .left_col {
    padding: 0;
    padding-top: 50px;
    box-sizing: border-box;
  }
}
