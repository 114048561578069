.success {
  padding: 50px;
  margin: auto;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
  border: 1px solid var(--primary-color);
}
